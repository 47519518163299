import { Doc } from "typesaurus";
import { ListNode } from "../../db";
import { Methods } from "../types";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import React from "react";
import TextareaAutosize from "react-textarea-autosize";

const Textarea = React.memo(
  ({
    value,
    className,
    node,
    refs,
    methods,
    onKeyDown,
    onChange,
    focus,
    textareaRef
  }: {
    value: string;
    className: string;
    node: Doc<ListNode>;
    methods: Methods;
    refs: React.RefObject<any>[];
    onKeyDown: (event: React.KeyboardEvent<HTMLTextAreaElement>) => void;
    onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
    focus: boolean;
    onBlur?: (event: React.FocusEvent<TextareaAutosize>) => void;
    textareaRef: React.RefObject<HTMLTextAreaElement>;
  }) => {
    useOnClickOutside(refs.concat([textareaRef]), () =>
      methods.stopEditing(node.ref.id)
    );

    // useEffect(() => {
    //   console.log("focus");
    //   focus && ref.current && ref.current.focus();
    // });

    return (
      <TextareaAutosize
        inputRef={textareaRef}
        className={className}
        onKeyDown={onKeyDown}
        onChange={onChange}
        value={value}
        onBlur={() => {
          if ((window as any).ignoreBlur) {
            focus && textareaRef.current && textareaRef.current.focus();
          } else {
            methods.stopEditing(node.ref.id);
          }
          (window as any).ignoreBlur = false;
        }}
        autoFocus
        // onBlur={(e: any) => focus && ref.current && ref.current.focus()}
        // onFocus={() => methods.startEditing(node.ref.id)}
      />
    );
  }
);

export default Textarea;
