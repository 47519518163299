import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import * as firebase from "firebase";

firebase.initializeApp({
  apiKey: "AIzaSyALkEmj_ayqfTyfGsm4fZxf6mxMrjHLpvs",
  projectId: "dodo-40542",
  authDomain: "dodo-40542.firebaseapp.com",
  storageBucket: "gs://dodo-40542.appspot.com"
});

firebase.firestore().settings({
  cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED
});

firebase.firestore().enablePersistence();

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
