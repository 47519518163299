import React from "react";
import { User } from "./db";

interface IAppContext {
  searchQuery: string;
}

export const AppContext = React.createContext<IAppContext>({
  searchQuery: ""
});
