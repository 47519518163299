import { Doc, Ref } from "typesaurus";
import { ListNode } from "../db";
import { ModelUpdate } from "typesaurus/update";

export const getPrevSibling = (nodes: Doc<ListNode>[], node: Doc<ListNode>) => {
  return nodes.find(
    n =>
      n.data.parentId === node.data.parentId &&
      n.data.order === node.data.order - 1
  );
};

export const getNextSibling = (nodes: Doc<ListNode>[], node: Doc<ListNode>) => {
  return nodes.find(
    n =>
      n.data.parentId === node.data.parentId &&
      n.data.order === node.data.order + 1
  );
};

export const extractImgSrc = (html: string) => {
  var m,
    urls = [],
    rex = /<img[^>]+src="?([^"\s]+)"?[\s/]*>/g;

  while ((m = rex.exec(html))) {
    urls.push(m[1]);
  }

  return urls;
};

export const addUpdatedAt = (
  data: ModelUpdate<ListNode>
): ModelUpdate<ListNode> => {
  return { ...data, updatedAt: +new Date() };
};

export const getChildren = (nodes: Doc<ListNode>[], nodeId: string) => {
  return nodes.filter(n => n.data.parentId === nodeId);
};
