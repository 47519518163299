import React, { useState, useEffect } from "react";
import { debounce } from "lodash";
import { RangeStatic, Sources } from "quill";
import { update, Doc } from "typesaurus";
import classNames from "classnames";
import { Link } from "react-router-dom";
// import hljs from "highlight.js";
// import "../../node_modules/highlight.js/styles/darcula.css";
import ReactQuill, { Quill } from "react-quill";
import { ListNode } from "../../db";
import * as firebase from "firebase";
import { ImageUpload } from "../../lib/imageUpload";
import { extractImgSrc, addUpdatedAt } from "../../utils/misc";

Quill.register("modules/imageUpload", ImageUpload);

const debounced = debounce((func: Function) => {
  func();
}, 1000);

interface IEditorProps {
  value?: string;
  nodeId: string;
  onFocus?: (range: RangeStatic, source: Sources, editor: any) => void;
  onBlur?: (previousRange: RangeStatic, source: Sources, editor: any) => void;
  onChange: (value: string) => any;
}

class Editor extends React.Component<IEditorProps> {
  QUILL_MODULES = {
    // syntax: { highlight: (text: string) => hljs.highlightAuto(text).value },
    syntax: false,
    toolbar: [
      { header: 2 },
      "bold",
      "italic",
      "underline",
      "strike",
      { list: "ordered" },
      { list: "bullet" },
      "link",
      "image",
      "code-block"
    ],
    imageUpload: {
      customUploader: (file: File, callback: (url: any) => void) => {
        const storageRef = firebase.storage().ref();
        const metadata = {
          contentType: file.type
        };
        const uploadTask = storageRef
          .child("images/node-" + this.props.nodeId + "/" + file.name)
          .put(file, metadata);

        uploadTask.on("state_changed", null, null, () => {
          uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
            console.log("File available at", downloadURL);
            callback(downloadURL);
          });
        });
      }
    }
  };

  render() {
    const { value, onFocus, onBlur, onChange } = this.props;

    return (
      <ReactQuill
        modules={this.QUILL_MODULES}
        value={value || ""}
        onFocus={onFocus}
        onBlur={onBlur}
        onChange={(_a, _b, _c, editor) => {
          onChange(editor.getHTML());
        }}
      />
    );
  }
}

const Sidebar = ({ node }: { node?: Doc<ListNode> }) => {
  const [content, setContent] = useState();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setContent(node ? node.data.note : null);
  }, [node]);

  if (!node) {
    return null;
  }

  const save = (value: string) => () => {
    node &&
      update(
        node.ref,
        addUpdatedAt({
          note: value,
          images: extractImgSrc(value)
        })
      );
  };

  const handleNoteChange = (value: string) => {
    setContent(value);
    debounced(save(value));
  };

  return (
    <div
      className={classNames("Tree__editor", {
        split: node
      })}
    >
      <h1 className="Tree__editor__header">
        <Link className="Header__link" to={`/node/${node.ref.id}/full`}>
          {node.data.content || "New Entry"}
        </Link>

        <Link className="Editor__header__close" onClick={save(content)} to="/">
          <i className="icon ion-ios-close"></i>
        </Link>
      </h1>
      <Editor
        nodeId={node.ref.id}
        key={node.ref.id}
        // onFocus={() => setHasToUpdateQuill(false)}
        // onBlur={() => setHasToUpdateQuill(true)}
        value={content}
        onChange={handleNoteChange}
      />
    </div>
  );
};

export default Sidebar;
