import { update, Doc } from "typesaurus";
import { ListNode, User } from "../../db";
import { LocalState, Methods } from "../types";
import { findKey } from "lodash";
import classNames from "classnames";
import React, { useContext } from "react";
import {
  createNode,
  outdent,
  indent,
  moveUp,
  moveDown,
  removeNode
} from "../../actions";
import "./ActionBar.css";
import { addUpdatedAt } from "../../utils/misc";
import { AppContext } from "../../contexts";

interface IProps {
  allNodes: Doc<ListNode>[];
  localState: LocalState;
  innerRef: React.RefObject<HTMLDivElement>;
  currentUser: User;
  methods: Methods;
}

const ActionBar = ({
  allNodes,
  localState,
  innerRef,
  currentUser,
  methods
}: IProps) => {
  const performAction = (action: (node: Doc<ListNode>) => void) => (
    e: React.TouchEvent
  ) => {
    e.preventDefault();
    e.stopPropagation();
    (window as any).ignoreBlur = true;
    const editedNode = findKey(localState, x => x.editing);
    const node = allNodes.find(n => n.ref.id === editedNode);
    !node && console.error("Node was not found", editedNode);
    node && action(node);
    // (window as any).ignoreBlur = false;
  };
  const { searchQuery } = useContext(AppContext);

  return (
    <div
      ref={innerRef}
      className={classNames("ActionBar", {
        visible: findKey(localState, x => x.editing)
      })}
    >
      <i
        onTouchStart={performAction(node =>
          update(
            node.ref,
            addUpdatedAt({
              completed: !node.data.completed
            })
          )
        )}
        // className="icon ion-ios-checkmark"
        className="icon ion-ios-checkmark-circle"
      ></i>
      <i
        onTouchStart={performAction(node =>
          createNode(allNodes, node, currentUser, methods)
        )}
        // className="icon ion-ios-add"
        className="icon ion-ios-add-circle"
      ></i>

      <i
        onTouchStart={event =>
          !searchQuery &&
          performAction(node => outdent(allNodes, node, currentUser))(event)
        }
        // className="icon ion-ios-arrow-round-back"
        className={classNames("icon ion-ios-arrow-dropleft-circle", {
          disabled: searchQuery
        })}
      ></i>
      <i
        onTouchStart={event =>
          !searchQuery &&
          performAction(node => indent(allNodes, node, currentUser))(event)
        }
        // className="icon ion-ios-arrow-round-forward"
        className={classNames("icon ion-ios-arrow-dropright-circle", {
          disabled: searchQuery
        })}
      ></i>
      <i
        onTouchStart={event =>
          !searchQuery &&
          performAction(node => {
            moveUp(allNodes, node, currentUser);
          })(event)
        }
        // className="icon ion-ios-arrow-round-up"
        className={classNames("icon ion-ios-arrow-dropup-circle", {
          disabled: searchQuery
        })}
      ></i>
      <i
        onTouchStart={event =>
          !searchQuery &&
          performAction(node => moveDown(allNodes, node, currentUser))(event)
        }
        // className="icon ion-ios-arrow-round-down"
        className={classNames("icon ion-ios-arrow-dropdown-circle", {
          disabled: searchQuery
        })}
      ></i>
      <i
        onTouchStart={performAction(node =>
          removeNode(allNodes, node, currentUser, methods)
        )}
        // className="icon ion-ios-close"
        className="icon ion-ios-close-circle"
      ></i>
      {/* <i className="icon ion-ios-document"></i> */}
    </div>
  );
};

export default ActionBar;
