import { Doc, batch, where, query, update, ref, remove, set } from "typesaurus";
import { ListNode, nodesCollection, User } from "../db";
import { sortBy, some, throttle } from "lodash";
import { Methods } from "../components/types";
import { getPrevSibling, addUpdatedAt, getNextSibling } from "../utils/misc";

// INFO: add, get kills offline, so don't use it please.

export const reorderSiblings = async (
  parentId: string | null,
  currentUser: User
) => {
  const { update, commit } = batch();
  const newNodes = await query(nodesCollection, [
    where("authorId", "==", currentUser.uid)
  ]);
  sortBy(
    newNodes.filter(
      n => n.data.parentId === parentId || (!n.data.parentId && !parentId)
    ),
    n => n.data.order
  ).forEach((n, i) => {
    update(n.ref, addUpdatedAt({ order: i }));
  });
  commit();
};

export const saveContent = throttle((node: Doc<ListNode>, content: string) => {
  update(node.ref, addUpdatedAt({ content })).catch(e =>
    console.warn("Node doesn't exist anymore")
  );
}, 500);

export const createNodeAbove = async (
  node: Doc<ListNode>,
  currentUser: User,
  methods: Methods
) => {
  const newNodeRef = ref(nodesCollection);
  methods.stopEditing(node.ref.id);
  methods.startEditing(newNodeRef.id);

  set(newNodeRef, {
    authorId: currentUser.uid,
    content: "",
    parentId: node.data.parentId,
    order: node.data.order - 0.1,
    updatedAt: +new Date()
  });

  reorderSiblings(node.data.parentId, currentUser);
};

export const createNode = async (
  nodes: Doc<ListNode>[],
  node: Doc<ListNode>,
  currentUser: User,
  methods: Methods
) => {
  const newNodeRef = ref(nodesCollection);
  methods.stopEditing(node.ref.id);
  methods.startEditing(newNodeRef.id);

  const anyChildren = some(nodes, n => n.data.parentId === node.ref.id);
  if (anyChildren && !node.data.collapse) {
    set(newNodeRef, {
      authorId: currentUser.uid,
      content: "",
      parentId: node.ref.id,
      order: -1,
      updatedAt: +new Date()
    });

    reorderSiblings(node.ref.id, currentUser);
  } else {
    set(newNodeRef, {
      authorId: currentUser.uid,
      content: "",
      parentId: node.data.parentId,
      order: node.data.order + 0.1,
      updatedAt: +new Date()
    });

    reorderSiblings(node.data.parentId, currentUser);
  }
};

export const removeNode = async (
  nodes: Doc<ListNode>[],
  node: Doc<ListNode>,
  currentUser: User,
  methods: Methods
) => {
  remove(node.ref);
  reorderSiblings(node.data.parentId, currentUser);
  methods.stopEditing(node.ref.id);
  const prevSibling = getPrevSibling(nodes, node);
  const nextSibling = getNextSibling(nodes, node);
  if (prevSibling) {
    methods.startEditing(prevSibling.ref.id);
    // TODO: prompt triggers onBlur on input so if you want to prevent it set this one to true
    // (window as any).ignoreBlur = true;
  } else if (nextSibling) {
    methods.startEditing(nextSibling.ref.id);
  }
};

export const indent = async (
  nodes: Doc<ListNode>[],
  node: Doc<ListNode>,
  currentUser: User
) => {
  const prevSibling = nodes.find(
    n =>
      n.data.parentId === node.data.parentId &&
      n.data.order === node.data.order - 1
  );
  // TODO: if it becomes root node set rootId to self.id
  //       in case of tab set root Id to parent rootId
  if (prevSibling) {
    const prevSiblingChildren = nodes.filter(
      n => n.data.parentId === prevSibling.ref.id
    );
    update(
      node.ref,
      addUpdatedAt({
        parentId: prevSibling.ref.id,
        order: prevSiblingChildren.length
      })
    );
    reorderSiblings(prevSibling.data.parentId, currentUser);
    reorderSiblings(prevSibling.ref.id, currentUser);
  }
};

export const outdent = async (
  nodes: Doc<ListNode>[],
  node: Doc<ListNode>,
  currentUser: User
) => {
  const parent = nodes.find(n => n.ref.id === node.data.parentId);
  if (parent) {
    update(
      node.ref,
      addUpdatedAt({
        parentId: parent.data.parentId,
        order: parent.data.order + 0.1
      })
    );
    reorderSiblings(node.data.parentId, currentUser); // reorder prev siblings
    reorderSiblings(parent.data.parentId, currentUser); // reorder new siblings
  }
};

export const moveDown = async (
  nodes: Doc<ListNode>[],
  node: Doc<ListNode>,
  currentUser: User
) => {
  const nextSibling = nodes.find(
    n =>
      n.data.parentId === node.data.parentId &&
      n.data.order === node.data.order + 1
  );
  if (nextSibling) {
    update(node.ref, addUpdatedAt({ order: nextSibling.data.order }));
    update(nextSibling.ref, addUpdatedAt({ order: node.data.order }));
  } else {
    // Let's try to find parent next sibling
    const parent = nodes.find(n => n.ref.id === node.data.parentId);
    if (parent) {
      const parentNextSibling = nodes.find(
        n =>
          n.data.parentId === parent.data.parentId &&
          n.data.order === parent.data.order + 1
      );
      if (parentNextSibling) {
        const anySibling = nodes.find(
          n => n.data.parentId === node.data.parentId
        );
        update(
          node.ref,
          addUpdatedAt({
            parentId: parentNextSibling.ref.id,
            order: -1
          })
        );
        // Reordering prev list of children
        anySibling && reorderSiblings(anySibling.data.parentId, currentUser);
        // Reordering current list of children
        reorderSiblings(parentNextSibling.ref.id, currentUser);
      }
    }
  }
};

export const moveUp = async (
  nodes: Doc<ListNode>[],
  node: Doc<ListNode>,
  currentUser: User
) => {
  const prevSibling = nodes.find(
    n =>
      n.data.parentId === node.data.parentId &&
      n.data.order === node.data.order - 1
  );
  if (prevSibling) {
    update(node.ref, addUpdatedAt({ order: prevSibling.data.order }));
    update(prevSibling.ref, addUpdatedAt({ order: node.data.order }));
  } else {
    // Let's try to find parent prev sibling
    const parent = nodes.find(n => n.ref.id === node.data.parentId);
    if (parent) {
      const parentPrevSibling = nodes.find(
        n =>
          n.data.parentId === parent.data.parentId &&
          n.data.order === parent.data.order - 1
      );
      if (parentPrevSibling) {
        const children = nodes.filter(
          n => n.data.parentId === parentPrevSibling.ref.id
        );
        const anySibling = nodes.find(
          n => n.data.parentId === node.data.parentId
        );
        update(
          node.ref,
          addUpdatedAt({
            parentId: parentPrevSibling.ref.id,
            order: children.length
          })
        );
        // Reordering prev list of children
        anySibling && reorderSiblings(anySibling.data.parentId, currentUser);
        // Reordering current list of children
        reorderSiblings(parentPrevSibling.ref.id, currentUser);
      }
    }
  }
};
