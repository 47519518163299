import React from "react";
import "./Shortcuts.css";

const Shortcuts = () => {
  return (
    <div className="Shortcuts">
      <h2>Keyboard Shortcuts</h2>
      <div className="Shortcuts__item">
        <div>New Item</div>
        <div>↵</div>
      </div>
      <div className="Shortcuts__item">
        <div>New Above</div>
        <div>⇧↵</div>
      </div>
      <div className="Shortcuts__item">
        <div>Complete</div>
        <div>⌘↵</div>
      </div>
      <div className="Shortcuts__item">
        <div>Delete</div>
        <div>⌫</div>
      </div>
      <div className="Shortcuts__item">
        <div>Move up</div>
        <div>⇧⌘↑</div>
      </div>
      <div className="Shortcuts__item">
        <div>Move down</div>
        <div>⇧⌘↓</div>
      </div>
      <div className="Shortcuts__item">
        <div>Indent</div>
        <div>⇥</div>
      </div>
      <div className="Shortcuts__item">
        <div>Outdent</div>
        <div>⇧⇥</div>
      </div>
    </div>
  );
};

export default Shortcuts;
