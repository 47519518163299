import React, { useRef, useContext } from "react";
import useMethods from "use-methods";
import Node from "../Node/Node";
import { Doc } from "typesaurus";
import { ListNode, User } from "../../db";
import "./Tree.css";
import { sortBy } from "lodash";
import classNames from "classnames";
import { LocalState } from "../types";
import Shortcuts from "../Shortcuts/Shortcuts";
import ActionBar from "../ActionBar/ActionBar";
import "react-splitter-layout/lib/index.css";
import { AppContext } from "../../contexts";

const initialLocalState: LocalState = {};

const transformers = (state: LocalState) => ({
  startEditing(id: string) {
    state[id] = state[id] || {};
    state[id].editing = true;
    return state;
  },
  stopEditing(id: string) {
    state[id] = state[id] || {};
    state[id].editing = false;
    return state;
  },
  setAsVisible(id: string) {
    state[id] = state[id] || {};
    state[id].visible = true;
    return state;
  }
});

interface IProps {
  currentUser: User;
  nodes: Doc<ListNode>[];
  split?: boolean;
}

const Tree = ({ currentUser, split, nodes }: IProps) => {
  const [localState, methods] = useMethods(transformers, initialLocalState);
  const actionBarRef = useRef<HTMLDivElement>(null);
  const { searchQuery } = useContext(AppContext);

  if (!nodes) {
    return <div></div>;
  }

  const rootNodes = nodes.filter(n => !n.data.parentId);
  if (rootNodes.length === 0) {
    return <div>Create your first item</div>;
  }

  const renderNode = (node: Doc<ListNode>) => {
    return (
      <Node
        key={node.ref.id}
        refs={[actionBarRef]}
        localState={localState}
        methods={methods}
        currentUser={currentUser}
        nodes={nodes}
        node={node}
      ></Node>
    );
  };

  return (
    <div
      className={classNames("Tree__list", {
        split
      })}
    >
      <div style={{ display: "none" }}>
        {nodes.map(
          node =>
            node.data.images &&
            node.data.images.map(i => {
              return <img key={i} src={i} alt={node.ref.id} />;
            })
        )}
      </div>
      <ul className="Tree__list__items">
        {sortBy(rootNodes, n => n.data.order).map(n => renderNode(n))}
      </ul>
      <ActionBar
        innerRef={actionBarRef}
        allNodes={nodes}
        localState={localState}
        currentUser={currentUser}
        methods={methods}
      />
      {!split && !searchQuery && <Shortcuts />}
    </div>
  );
};

export default Tree;
