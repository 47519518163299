import React, { useState, useEffect, useContext } from "react";
import SplitterLayout from "react-splitter-layout";
import Sidebar from "../Sidebar/Sidebar";
import { Route } from "react-router-dom";
import { Doc, where, onQuery, add } from "typesaurus";
import { ListNode, User, nodesCollection } from "../../db";
import { DESKTOP_BREAKPOINT } from "../../constants";
import useWindowSize from "../../hooks/useWindowSize";
import Tree from "../Tree/Tree";
import "./View.css";

const View = ({ currentUser }: { currentUser: User }) => {
  const [allNodes, setNodes] = useState<Doc<ListNode>[] | null>(null);
  const splitPaneWidth = window.localStorage.getItem("splitPaneWidth");
  const [width, setWidth] = useState(
    (splitPaneWidth && parseInt(splitPaneWidth)) || 50
  );
  const windowSize = useWindowSize();

  useEffect(() => {
    onQuery(
      nodesCollection,
      [where("authorId", "==", currentUser.uid)],
      setNodes
    );
  }, [currentUser.uid]);

  useEffect(() => {
    if (allNodes && allNodes.length === 0) {
      add(nodesCollection, {
        authorId: currentUser.uid,
        content: "You first entry",
        parentId: null,
        order: 0,
        updatedAt: +new Date()
      });
    }
  }, [allNodes, currentUser.uid]);

  const renderSidebar = (nodeId: string) => {
    return (
      allNodes && <Sidebar node={allNodes.find(n => n.ref.id === nodeId)} />
    );
  };

  if (!allNodes) {
    return null;
  }
  console.log("nodes", allNodes.length);

  return (
    <div className="View">
      <Route
        path="/"
        exact
        render={_ => <Tree currentUser={currentUser} nodes={allNodes} />}
      />
      <Route
        path="/node/:nodeId"
        exact
        render={routeProps =>
          windowSize.width && windowSize.width < DESKTOP_BREAKPOINT ? (
            renderSidebar(routeProps.match.params.nodeId)
          ) : (
            <SplitterLayout
              primaryMinSize={25}
              percentage
              secondaryInitialSize={width}
              onDragEnd={() =>
                window.localStorage.setItem("splitPaneWidth", width.toString())
              }
              onSecondaryPaneSizeChange={(secondaryPaneSize: number) => {
                setWidth(secondaryPaneSize);
              }}
            >
              <Tree split currentUser={currentUser} nodes={allNodes} />
              {renderSidebar(routeProps.match.params.nodeId)}
            </SplitterLayout>
          )
        }
      />
      <Route
        path="/node/:nodeId/full"
        render={routeProps => renderSidebar(routeProps.match.params.nodeId)}
      />
    </div>
  );
};

export default View;
