import React, { useEffect, useState } from "react";
import firebase from "firebase";
import "./App.css";
import { User } from "./db";
import { BrowserRouter as Router, Link } from "react-router-dom";
import View from "./components/View/View";
import { AppContext } from "./contexts";

const provider = new firebase.auth.GoogleAuthProvider();

const App: React.FC = () => {
  const [currentUser, setCurrentUser] = useState<User | null | undefined>();
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    firebase.auth().onAuthStateChanged(untypedUser => {
      const user = untypedUser as User;
      setCurrentUser(user || null); // null means 'unauthorized'
    });
  }, []);

  const login = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    firebase.auth().signInWithRedirect(provider);
  };

  // const logout = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
  //   e.preventDefault();
  //   firebase.auth().signOut();
  // };

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  return (
    <AppContext.Provider value={{ searchQuery }}>
      <Router>
        <div className="App">
          <div className="Topbar">
            <div className="Topbar__home">
              <Link to="/">Home</Link>
            </div>
            <div className="Topbar__right">
              {currentUser && (
                <input
                  className="SearchField"
                  type="text"
                  placeholder="Search..."
                  onChange={onSearchChange}
                />
              )}
              <div className="Topbar__login">
                {currentUser === undefined ? (
                  <span>Loading...</span>
                ) : currentUser === null ? (
                  <a href="#login" onClick={login}>
                    Login
                  </a>
                ) : (
                  <div className="Topbar__login__info">{currentUser.email}</div>
                  // <a href="#logout" onClick={logout}>
                  //   Logout
                  // </a>
                )}
              </div>
            </div>
          </div>
          {currentUser && <View currentUser={currentUser} />}
          <div className=""></div>
        </div>
      </Router>
    </AppContext.Provider>
  );
};

export default App;
