import React, { useState, useEffect, useRef, useContext } from "react";
import classNames from "classnames";
import "./Node.css";
import { update, Doc } from "typesaurus";
import { sortBy } from "lodash";
import { ListNode, User } from "../../db";
import { LocalState, Methods } from "../types";
import {
  saveContent,
  createNode,
  removeNode,
  indent,
  outdent,
  moveDown,
  moveUp,
  createNodeAbove
} from "../../actions";
import { Link } from "react-router-dom";
import Textarea from "../Textarea/Textarea";
import {
  getPrevSibling,
  getNextSibling,
  addUpdatedAt,
  getChildren
} from "../../utils/misc";
import { AppContext } from "../../contexts";
import { childrenMatchQuery, matchesQuery } from "../../utils/search";

interface IProps {
  nodes: Doc<ListNode>[];
  node: Doc<ListNode>;
  localState: LocalState;
  refs: React.RefObject<any>[];
  methods: Methods;
  currentUser: User;
}

// TODO: Move functions outside of component body
const Node = ({
  nodes,
  methods,
  localState,
  currentUser,
  refs,
  node
}: IProps) => {
  const { searchQuery } = useContext(AppContext);
  const [state, setState] = useState({
    content: node.data.content,
    updatedAt: node.data.updatedAt
  });
  const [deleting, setDeleting] = useState(false);
  const anyChildren = childrenMatchQuery(
    searchQuery,
    getChildren(nodes, node.ref.id),
    nodes
  );
  const localNodeState = localState[node.ref.id] || {};
  const ref = useRef<HTMLTextAreaElement>(null);

  // NOTE: Don't add state.updatedAt to hook deps
  useEffect(() => {
    node.data.updatedAt >= state.updatedAt &&
      node.data.content !== state.content &&
      setState({ content: node.data.content, updatedAt: node.data.updatedAt });
  }, [node.data.content, node.data.updatedAt]);

  const toggleCollapse = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    update(node.ref, addUpdatedAt({ collapse: !node.data.collapse }));
  };

  const onEditing = (e: any) => {
    e.stopPropagation();
    if (localNodeState.editing) {
      return;
    }
    methods.startEditing(node.ref.id);
  };

  const edit = (e: any) => {
    setState({
      content: e.target.value,
      updatedAt: +new Date()
    });
    saveContent(node, e.target.value);
  };

  const onKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter" && !e.metaKey && !e.shiftKey) {
      e.preventDefault();
      console.log("node below");
      createNode(nodes, node, currentUser, methods);
    }
    if (e.key === "Enter" && !e.metaKey && e.shiftKey) {
      e.preventDefault();
      console.log("node above");
      createNodeAbove(node, currentUser, methods);
    }
    // Handle backspace key
    if (e.keyCode === 8 && state.content.length === 0) {
      e.preventDefault();
      setDeleting(true);

      if (anyChildren || (node.data.note && node.data.note !== "<p><br></p>")) {
        const answer = window.confirm(
          "Do you really want to remove this item?"
        );
        if (answer) {
          removeNode(nodes, node, currentUser, methods);
        }
      } else {
        removeNode(nodes, node, currentUser, methods);
      }
    }
    if (e.key === "Enter" && e.metaKey) {
      e.preventDefault();
      update(
        node.ref,
        addUpdatedAt({
          completed: !node.data.completed
        })
      );
    }
    // Handle Tab key
    if (e.keyCode === 9 && !e.shiftKey) {
      e.preventDefault();
      !searchQuery && indent(nodes, node, currentUser);
    }
    // Handle escape key
    if (e.keyCode === 27) {
      methods.stopEditing(node.ref.id);
    }
    // Handle shift tab
    if (e.keyCode === 9 && e.shiftKey) {
      e.preventDefault();
      !searchQuery && outdent(nodes, node, currentUser);
    }
    // Handle move up action - up + cmd + shift
    if (e.keyCode === 38 && e.metaKey && e.shiftKey) {
      e.preventDefault();
      !searchQuery && moveUp(nodes, node, currentUser);
    }
    // Handle arrow down key
    if (e.keyCode === 40 && e.metaKey && e.shiftKey) {
      e.preventDefault();
      !searchQuery && moveDown(nodes, node, currentUser);
    }
    // Handle up key - move cursor one node higher
    if (e.keyCode === 38 && !e.metaKey) {
      const prevSibling = getPrevSibling(nodes, node);
      if (prevSibling) {
        e.preventDefault();
        methods.stopEditing(node.ref.id);
        methods.startEditing(prevSibling.ref.id);
      }
    }
    // Handle down key - move cursor one node lower
    if (e.keyCode === 40 && !e.metaKey) {
      const nextSibling = getNextSibling(nodes, node);
      if (nextSibling) {
        e.preventDefault();
        methods.stopEditing(node.ref.id);
        methods.startEditing(nextSibling.ref.id);
      }
    }
  };

  const renderChildren = () => {
    if (!searchQuery && (node.data.collapse || !anyChildren)) {
      return null;
    }

    const children = nodes.filter(n => n.data.parentId === node.ref.id);

    return (
      <ul className="Node__children">
        {sortBy(children, n => n.data.order).map(n => (
          <Node
            key={n.ref.id}
            methods={methods}
            refs={refs}
            localState={localState}
            currentUser={currentUser}
            nodes={nodes}
            node={n}
          />
        ))}
      </ul>
    );
  };

  if (!matchesQuery(searchQuery, node.ref.id, node.data.content, nodes)) {
    return null;
  }

  // methods.setAsVisible(node.ref.id);

  return (
    <li className="Node">
      <div
        className={classNames("Node__title", {
          completed: node.data.completed
        })}
      >
        <div className="Node__controls">
          {anyChildren ? (
            <>
              <span
                className="Node__control Node__arrow"
                onClick={e => {
                  !searchQuery && toggleCollapse(e);
                }}
              >
                {node.data.collapse && !searchQuery ? (
                  <i className="icon ion-ios-arrow-forward noSelect"></i>
                ) : (
                  <i className="icon ion-ios-arrow-down noSelect"></i>
                )}
              </span>
              &nbsp;
            </>
          ) : node.data.completed ? (
            <span className="Node__control Node__dot">
              <i className="icon ion-ios-checkmark-circle"></i>
            </span>
          ) : (
            <span className="Node__control Node__dot">&#9679;</span>
            // <span className="Node__dot">{node.data.order}</span>
          )}
        </div>

        {localNodeState.editing ? (
          <>
            <Textarea
              textareaRef={ref}
              className="Node__input"
              value={state.content}
              node={node}
              refs={refs}
              methods={methods}
              onKeyDown={onKeyDown}
              onChange={edit}
              focus={
                localState[node.ref.id] && !!localState[node.ref.id].editing
              }
            />
            <div style={{ minWidth: "20px" }}></div>
          </>
        ) : (
          <>
            <div className="Node__text">
              <span className="Node__content" onClick={onEditing}>
                {state.content || deleting ? state.content : "New Entry"}
              </span>

              {!deleting && (
                <Link
                  className={classNames("Node__icon", {
                    hasNote: node.data.note && node.data.note !== "<p><br></p>",
                    completed: node.data.completed
                  })}
                  to={`/node/${node.ref.id}`}
                >
                  &nbsp;<i className="icon ion-ios-document"></i>
                </Link>
              )}
            </div>
          </>
        )}
      </div>
      {renderChildren()}
    </li>
  );
};

export default Node;
