import { collection } from "typesaurus";

export type ListNode = {
  content: string;
  parentId: string | null;
  collapse?: boolean;
  // editing?: boolean;
  // active?: boolean;
  order: number;
  note?: string;
  // rootId: string;
  authorId: string;
  images?: string[];
  completed?: boolean;
  updatedAt: number;
};

export type RootNodeAccess = {
  adminId: string;
  memberIds: string[]; // must contain adminId as well
};

export interface User {
  uid: string;
  displayName: string;
  photoURL?: string;
  email?: string;
}

export const accessCollection = collection<RootNodeAccess>("rootNodesAccess");
export const nodesCollection = collection<ListNode>("nodes");
