import { memoize, some } from "lodash";
import { ListNode } from "../db";
import { getChildren } from "./misc";
import { Doc } from "typesaurus";
import { node } from "prop-types";

(window as any).boom = 0;

export const matchesQuery = memoize(
  (
    searchQuery: string,
    nodeId: string,
    nodeContent: string,
    nodes: Doc<ListNode>[]
  ): boolean => {
    (window as any).boom += 1;
    if (!searchQuery || !nodeContent) {
      return true;
    }

    const regex = new RegExp(searchQuery, "i");
    if (nodeContent.match(regex)) {
      return true;
    }

    const children = getChildren(nodes, nodeId);

    if (children.length > 0) {
      return childrenMatchQuery(searchQuery, children, nodes);
    } else {
      return false;
    }
  },
  (searchQuery: string, nodeId: string) => {
    return `${searchQuery}-${nodeId}`;
  }
);

export function childrenMatchQuery(
  searchQuery: string,
  children: Doc<ListNode>[],
  allNodes: Doc<ListNode>[]
): boolean {
  return some(
    children.map(child =>
      matchesQuery(searchQuery, child.ref.id, child.data.content, allNodes)
    )
  );
}
